.authBackground{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../img/login_bg.jpg');
    position: relative;
}

.authBackground:before{
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background-color: #000000bd;
}

.authForm .card{
    box-shadow: 0 25px 30px -13px rgb(40 40 40 / 40%);
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 30px;
    transform: translateZ(0);
    transition: all .3s linear;
}

.authForm .card .card-header{
    padding: 15px 15px 0;
    background-color: #fff;
    border-bottom: none!important;
}

.authForm .card .card-header h3{
    color: #3c3f43;
    margin: 20px 0 10px;
}

.authForm .card label{
    color: #3c3f43;
    font-size: .75rem;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.authForm .login-btn{
    border: 1px solid #ffa534;
    color: #fff;
    background-color: #ffa534;
    cursor: pointer;
    opacity: 1;
    transition: 0.4s;
}

.authForm .login-btn:hover{
    background-color: #f78b03;
}